import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Some of my projects`}</h1>
    <h2>{`Svelte REPL projects`}</h2>
    <Link to="/projects/svelte-repl-projects" className="block" mdxType="Link">
  <img src="/svelte-logo-horizontal.jpg" width="450" height="225" alt="Svelte Logo" />
    </Link>
    <p>{`Check out some Svelte REPL projects I've built while learning Svelte`}</p>
    <h2>{`V4 of CZaas.com`}</h2>
    <Link to="/projects/czaas-v4" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2FScreen%20Shot%202019-08-12%20at%209.19.26%20PM.jpg?alt=media&token=058778a7-843f-4c2e-8435-7a025a5a7d1b" width="450" height="225" alt="V4 preview of my personal site" />
    </Link>
    <p>{`Moving from slow custom CMS to statically generated site built with gatsby js and powered by netlify CI/CD git based deployment.`}</p>
    <h2>{`V3 of CZaas.com`}</h2>
    <Link to="/projects/czaas-v3" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fczaas-v3-logo.jpg?alt=media&token=66490be3-2bce-45ee-9783-e2c7d91ea438" width="450" height="225" alt="V3 preview of my personal site" />
    </Link>
    <p>{`Unsatisfied with my previous ability to push new content on my site and have always wanted to build a CMS, I went ahead and did it.`}</p>
    <Link to="/projects/czaas-v3" mdxType="Link">View my write up</Link>
    <h2>{`MindBody Front End Engineer Homework`}</h2>
    <Link to="/projects/mb-fee-hw" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmb-fee-hw-logo.jpg?alt=media&token=cb8750ae-89ea-43ac-9825-540959c759bc" width="450" height="225" alt="Mindbody FEE Homework I did" />
    </Link>
    <p>{`An overview of the homework I did when applying to MindBody.`}</p>
    <Link to="/projects/mb-fee-hw" mdxType="Link">View my write up</Link>
    <h2>{`V2 of My Personal Site`}</h2>
    <Link to="/projects/czaas-v2" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fnode-czaas-logo.jpg?alt=media&token=fa4bd056-5433-4058-80de-3623dc20bf2f" width="450" height="225" alt="Screenshot of V2 of my site" />
    </Link>
    <p>{`I rebuilt my website using Node JS Express server with an API that fetches static markdown files and a front end SPA using Hyperapp.`}</p>
    <Link to="/projects/czaas-v2" mdxType="Link">View my write up</Link>
    <h2>{`Sight Words`}</h2>
    <Link to="/projects/sightwords" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fsightwords-logo.jpg?alt=media&token=c2e47a49-0faf-4696-92bc-e683c2e31147" width="450" height="225" alt="Screenshot of Sightwords app I built" />
    </Link>
    <p>{`An app for new readers to learn words you should know on sight. Hyperapp (JS Library), Speech Synthesis API, localStorage API`}</p>
    <Link to="/projects/sightwords" mdxType="Link">View my write up</Link>
    <h2>{`Chads Glass`}</h2>
    <Link to="/projects/chads-glass" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fchads-glass-logo.jpg?alt=media&token=fc9786a2-b6a6-4461-9789-3197757f3761" width="450" height="225" alt="Chads Glass Logo" />
    </Link>
    <p>{`Using Instagrams old API, I built an web app that consumed all images of a Chad's account to give him control over the look and feel of his site.`}</p>
    <Link to="/projects/chads-glass" mdxType="Link">View my write up</Link>
    <h2>{`Animal Selector`}</h2>
    <Link to="/projects/animal-selector" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fanimal-selector-logo.jpg?alt=media&token=91bb067b-28dd-46e7-9db4-817bf2ee0ac0" width="450" height="225" alt="Animal Selector logo" />
    </Link>
    <p>{`Fiddling with Google's material design standards both feel and motion. React, Motion`}</p>
    <Link to="/projects/animal-selector" mdxType="Link">View my write up</Link>
    <h2>{`Testa Painting`}</h2>
    <Link to="/projects/testa-painting" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ftesta-painting-logo.jpg?alt=media&token=14db02d4-0960-4026-872e-7387057047d2" width="450" height="225" alt="Testa Painting Logo" />
    </Link>
    <p>{`Built a site for a local painting contractor HTML, SASS/CSS, JS`}</p>
    <Link to="/projects/testa-painting" mdxType="Link">View my write up</Link>
    <h2>{`Mann Pools`}</h2>
    <Link to="/projects/mann-pools" className="block" mdxType="Link">
  <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmann-pools-logo.jpg?alt=media&token=7e169d6d-20c4-49fb-a7d3-8dcc7b410444" width="450" height="225" alt="Mann Pools logo" />
    </Link>
    <p>{`I designed the layout where the content is easily readable and comprehensible. I used the latest practices of HTML, SASS/CSS, Javascript, and some PHP to create this responsive website.`}</p>
    <Link to="/projects/mann-pools" mdxType="Link">View my write up</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      